import {
    Button,
    Td,
    Text,
    Icon,
    Tr,
    useColorModeValue
  } from "@chakra-ui/react";
  import { Link } from 'react-router-dom';
  import { AiOutlinePlus, AiOutlineDownload, AiOutlineUpload, AiOutlineCloseCircle, AiOutlineCheck } from 'react-icons/ai';

  import Swal from 'sweetalert2';
  import {
    FaRegEdit,
    FaEye,
    FaRegTrashAlt
  } from "react-icons/fa";
  import AuthApi from "../../api/auth";
  import React, { Fragment, useState, useEffect } from "react"; 
  
  function ListRequestPre(props) {
    
    const {id, description, status: initialStatus, resource, company, date_register} = props;
    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.400");
    const [userDataProfile, setUserDataProfile] =  useState(JSON.parse(localStorage.getItem('user')))

    const [showButtons, setShowButtons] = useState(initialStatus === "Solicitado");
    const [status, setStatus] = useState(initialStatus); // Estado para manejar el texto del estado

    
    const handleClick = async (item, newStatus) => {
      console.log(item)

      let dataRequest = {
        id : item,
        state : newStatus
      }
      let iconShow = (newStatus == "Aprobado")? "success" : "error";

      try {
        let response = await AuthApi.changeStatusInitial(dataRequest);
        console.log(response.data);
        if (response.data.success === true) {
          
              Swal.fire({
                icon: iconShow,
                title: '',
                text: newStatus,
                //position: 'top-end',
                showConfirmButton: false,   
                timer: 3000,
                timerProgressBar: true 
              });
              setShowButtons(false);
              setStatus(newStatus);

        }
      } catch (err) {
        console.log(err);
      }
    };
    


    return (
      <Tr>
        <Td>
          {showButtons && status === "Solicitado" && (
            <>
              <Button bg="green.500" color="white" fontSize="xs" p="8px 20px" m={1} leftIcon={<AiOutlineCheck />} onClick={() => handleClick(id, "Aprobado")}>
              </Button>
              <Button bg="red.500" color="white" fontSize="xs" p="8px 20px" m={1} leftIcon={<AiOutlineCloseCircle />} onClick={() => handleClick(id, "Rechazado")}>
              </Button>
            </>
          )}
        </Td>
        <Td minWidth={{ sm: "80px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {description}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {resource}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {company}
          </Text>
        </Td>
        
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color={status === "Aprobado" ? "green.500" : status === "Rechazado" ? "red.500" : "gray.400"} fontWeight="bold">
            {status}
          </Text>
        </Td>
        <Td minWidth={{ sm: "30px" }} pl="0px">
          <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {date_register}
          </Text>
        </Td>
      </Tr>
    );
  }
  
  export default ListRequestPre;
  