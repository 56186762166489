// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Portal,
  Progress,
  SimpleGrid,
  Spacer,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
// assets
import peopleImage from "assets/img/people-image.png";
import logoChakra from "assets/svg/logo-white.svg";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  RocketIcon,
  StatsIcon,
  WalletIcon,
} from "components/Icons/Icons.js";

import {
  FaClipboard,
  FaFolder,
  FaCheckCircle,
  FaBox,
  FaClock,
  FaWrench,
  FaArrowCircleDown,
  FaFile,  
  FaUserTag,
  FaRegTimesCircle,
} from "react-icons/fa";

import DashboardTableRow from "components/Tables/DashboardTableRow";
import TimelineRow from "components/Tables/TimelineRow";
import React, { useState } from "react";
import { useEffect } from "react";
import Chart from "react-apexcharts";
import AuthApi from "api/auth";
import Select , { components } from 'react-select';
// react icons
import { BsArrowRight } from "react-icons/bs";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { dashboardTableData, timelineData } from "variables/general";
import { forEachChild } from "typescript";
import { Link } from 'react-router-dom';

export default function Dashboard() {
  const value = "$100.000";
  // Chakra Color Mode
  const { colorMode, toggleColorMode } = useColorMode();
  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const [countDashboard, setCountDashboard] = useState([]);
  const [countDashboardSolicitud, setCountDashboardSolicitud] = useState(0);
  const [countDashboardPendiente, setCountDashboardPendiente] = useState(0);
  const [countDashboardAprobada, setCountDashboardAprobada] = useState(0);
  const [countDashboardPreparacion, setCountDashboardPreparacion] = useState(0);
  const [countDashboardDisponible, setCountDashboardDisponible] = useState(0);
  const [countDashboardEntregado, setCountDashboardEntregado] = useState(0);
  const [countDashboardAsignado, setCountDashboardAsignado] = useState(0);
  const [countDashboardBaja, setCountDashboardBaja] = useState(0);
  const [countDashboardProximosDevolucion, setCountDashboardProximosDevolucion] = useState(0);
  const [listProduct, setListProduct] = useState([])
  const [selectedOptionProduct, setselectedOptionProduct] = useState('')
  const [productRequest, setProductRequest] = useState(0)
  const [productRequestLabel, setProductRequestLabel] = useState(null)


  const [namesSoft, setNamesSoft] = useState([]);
  const [countSoft, setCountSoft] = useState([]);

  const [namesProd, setNamesProd] = useState([]);
  const [countProd, setCountProd] = useState([]);

  const [series, setSeries] = useState([
    {
      type: "area",
      name: "Mobile apps",
      data: [190, 220, 205, 350, 370, 450, 400, 360, 210, 250, 292, 150],
    },
    {
      type: "area",
      name: "Websites",
      data: [400, 291, 121, 117, 25, 133, 121, 211, 147, 25, 201, 203],
    },
  ]);
  const overlayRef = React.useRef();

  const getCountDashboard = async () => {
    try {
      let response = await AuthApi.getCountDashboard();
      if (response.data.success === true) {
        setCountDashboard(response.data.data);
        setCountDashboardSolicitud(response.data.data.solicitud.count_request);
        setCountDashboardPendiente(
          response.data.data.pendiente_aprobacion.count_request
        );
        setCountDashboardAprobada(
          response.data.data.compra_aprobada.count_request
        );
        setCountDashboardPreparacion(
          response.data.data.preparacion.count_request
        );
        setCountDashboardDisponible(
          response.data.data.equipo_disponible.count_request
        );
        setCountDashboardEntregado(
          response.data.data.equipo_entregado.count_request
        );
        setCountDashboardAsignado(
          response.data.data.equipo_asignado.count_request
        );
        setCountDashboardBaja(response.data.data.equipo_baja.count_request);

        setCountDashboardProximosDevolucion(response.data.data.equipo_proximo_devolver.count_request)

        console.log(response.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCountDashboardByProduct = async (idProduct) => {
    console.log(idProduct)
    try {
      let response = await AuthApi.getCountDashboardByProduct(idProduct);
      if (response.data.success === true) {
        setCountDashboard(response.data.data);
        setCountDashboardSolicitud(response.data.data.solicitud.count_request);
        setCountDashboardPendiente(
          response.data.data.pendiente_aprobacion.count_request
        );
        setCountDashboardAprobada(
          response.data.data.compra_aprobada.count_request
        );
        setCountDashboardPreparacion(
          response.data.data.preparacion.count_request
        );
        setCountDashboardDisponible(
          response.data.data.equipo_disponible.count_request
        );
        setCountDashboardAsignado(
          response.data.data.equipo_asignado.count_request
        );
        setCountDashboardEntregado(
          response.data.data.equipo_entregado.count_request
        );
        setCountDashboardBaja(response.data.data.equipo_baja.count_request);

        setCountDashboardProximosDevolucion(response.data.data.equipo_proximo_devolver.count_request)

        console.log(response.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCountSoftware = async () => {
    try {
      let response = await AuthApi.getCountSoftware();
      if (response.data.success === true) {
        console.log(response.data.data);

        let arrayDataName = [];
        let arrayDataCount = [];
        response.data.data.forEach(element => {
          arrayDataName.push(element.name);
          arrayDataCount.push(element.count);
        });
        setNamesSoft(arrayDataName)
        setCountSoft(arrayDataCount)
      }
      
    } catch (err) {
      console.log(err);
    }
  };

  const getCountProducts = async () => {
    try {
      let response = await AuthApi.getCountProducts();
      if (response.data.success === true) {
        console.log(response.data.data);

        let arrayDataName = [];
        let arrayDataCount = [];
        response.data.data.forEach(element => {
          arrayDataName.push(element.name);
          arrayDataCount.push(element.count);
        });
        setNamesProd(arrayDataName)
        setCountProd(arrayDataCount)
      }
      
    } catch (err) {
      console.log(err);
    }
  };


  const getListProduct = async () => {
    try {
      let response = await AuthApi.getListProduct(1);
      if (response.data.success === true) {
        let arrayData = [];
          response.data.data.forEach(element => {
            let item = { value: element.id, label: element.name };
            arrayData.push(item);
          });
          setListProduct(arrayData)
      }
    } catch (err) {
      console.log(err);
    }
  };

  const barChartData = [
    {
      name: "Software",
      data: countSoft,
    },
  ];

  const barChartDataProd = [
    {
      name: "Productos",
      data: countProd,
    },
  ];

  const barChartOptions = {
    chart: {
      toolbar: {
        show: true,
      },
    },
    tooltip: {
      style: {
        backgroundColor: "red",
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          backgroundColor: "red",
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: namesSoft,
      show: true,
      labels: {
        show: false,
        style: {
          colors: "#fff",
          fontSize: "8px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      color: "#fff",
      labels: {
        show: true,
        style: {
          colors: "#fff",
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: false,
    },
    fill: {
      colors: "#ccc",
    },
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        columnWidth: "12px",
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 0,
            },
          },
        },
      },
    ],
  };

  const barChartOptionsProd = {
    chart: {
      toolbar: {
        show: true,
      },
    },
    tooltip: {
      style: {
        backgroundColor: "red",
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          backgroundColor: "red",
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: namesProd,
      show: true,
      labels: {
        show: false,
        style: {
          colors: "#fff",
          fontSize: "8px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      color: "#fff",
      labels: {
        show: true,
        style: {
          colors: "#fff",
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: false,
    },
    fill: {
      colors: "#ccc",
    },
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        columnWidth: "12px",
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 0,
            },
          },
        },
      },
    ],
  };

  const customStylesSelectModal = () => ({
    control: (provided, state) => ({
      ...provided,
      color: '#cbd5e0',
      borderRadius: "15px",
      padding: '4px',
      marginBottom: "10px",
      fontSize: '14px',
      borderColor: '#cbd5e0',
      '&:hover': {
        borderColor: '#cbd5e0',
      },
    }),
  });

  const handleCaptureValueProduct = (prodcutSelected) => {

    setselectedOptionProduct(prodcutSelected)
    setProductRequest(prodcutSelected.value);
    setProductRequestLabel(prodcutSelected.label);
    getCountDashboardByProduct(prodcutSelected.value);
    /*
    console.log("typeRequestDetail", typeRequestDetail)

    if(typeRequestDetail.label == "Hardware"){
      setIsLoadingProducts(true)
      getListProduct(typeRequestDetail.value);
      setIsShowSelectProductRequest(true)
    }else{
      setIsShowSelectProductRequest(false)
    }
    setselectedOptionType(typeRequestDetail)
    setTypeRequestDetail(typeRequestDetail.value);
    setTypeRequestDetailLabel(typeRequestDetail.label);
    */
  };

  useEffect(() => {
    getCountDashboard();
    getCountSoftware();
    getListProduct();
    getCountProducts();
  }, []);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Flex direction="row" w="100%">
        <Flex direction="column" w="100%">
          <Select
            styles={customStylesSelectModal()}
            value={selectedOptionProduct}
            onChange={handleCaptureValueProduct}
            options={listProduct}
            placeholder="Seleccione Producto"
          />
        </Flex>
      </Flex>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px">
         <Link to={{
          pathname: `/admin/list-status/2/${productRequest}`,
          state: 2
        }}>
          <Card minH="83px">
            <CardBody>
              <Flex flexDirection="row" align="center" justify="center" w="100%">
                <Stat me="auto">
                  <StatLabel
                    fontSize="sm"
                    color="gray.400"
                    fontWeight="bold"
                    pb=".1rem"
                  >
                    Equipos en <br/>Solicitud
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color={textColor}>
                      {countDashboardSolicitud}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                  <FaClipboard h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
            </CardBody>
          </Card>
        </Link>
        <Link to={{
          pathname: `/admin/list-status/3/${productRequest}`,
          state: 3 
        }}>
          <Card minH="83px">
            <CardBody>
              <Flex flexDirection="row" align="center" justify="center" w="100%">
                <Stat>
                  <StatLabel
                    fontSize="sm"
                    color="gray.400"
                    fontWeight="bold"
                    pb=".1rem"
                  >
                    Pendientes <br/>Aprobación
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color={textColor}>
                      {countDashboardPendiente}
                    </StatNumber>
                  </Flex>
                </Stat>
                <Spacer />
                <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                  <FaClock h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
            </CardBody>
          </Card>
        </Link>
        <Link to={{
          pathname: `/admin/list-status/4/${productRequest}`,
          state: 4
        }}>
          <Card minH="83px">
            <CardBody>
              <Flex flexDirection="row" align="center" justify="center" w="100%">
                <Stat me="auto">
                  <StatLabel
                    fontSize="sm"
                    color="gray.400"
                    fontWeight="bold"
                    pb=".1rem"
                  >
                    Compra <br/>Aprobadas
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                      {countDashboardAprobada}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                  <FaCheckCircle h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
            </CardBody>
          </Card>
        </Link>
        <Link to={{
          pathname: `/admin/list-status/7/${productRequest}`,
          state: 7
        }}>
          <Card minH="83px">
            <CardBody>
              <Flex flexDirection="row" align="center" justify="center" w="100%">
                <Stat me="auto">
                  <StatLabel
                    fontSize="sm"
                    color="gray.400"
                    fontWeight="bold"
                    pb=".1rem"
                  >
                    Equipos en <br/>Preparación
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                      {countDashboardPreparacion}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                  <FaWrench h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
            </CardBody>
          </Card>
        </Link>
        <Link to={{
          pathname: `/admin/list-status/8/${productRequest}`,
          state: 8 
        }}>
          <Card minH="83px">
            <CardBody>
              <Flex flexDirection="row" align="center" justify="center" w="100%">
                <Stat me="auto">
                  <StatLabel
                    fontSize="sm"
                    color="gray.400"
                    fontWeight="bold"
                    pb=".1rem"
                  >
                    Equipos <br/>Disponibles
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                      {countDashboardDisponible}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                  <FaFolder h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
            </CardBody>
          </Card>
        </Link>
        <Link to={{
          pathname: `/admin/list-status/9/${productRequest}`,
          state: 9 
        }}>
          <Card minH="83px">
            <CardBody>
              <Flex flexDirection="row" align="center" justify="center" w="100%">
                <Stat me="auto">
                  <StatLabel
                    fontSize="sm"
                    color="gray.400"
                    fontWeight="bold"
                    pb=".1rem"
                  >
                    Equipos <br/>Asignados
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                      {countDashboardAsignado}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                  <FaUserTag h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
            </CardBody>
          </Card>
        </Link>
        <Link to={{
          pathname: `/admin/list-status/10/${productRequest}`,
          state: 9 
        }}>
          <Card minH="83px">
            <CardBody>
              <Flex flexDirection="row" align="center" justify="center" w="100%">
                <Stat me="auto">
                  <StatLabel
                    fontSize="sm"
                    color="gray.400"
                    fontWeight="bold"
                    pb=".1rem"
                  >
                    Equipos <br/>Entregados
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                      {countDashboardEntregado}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                  <FaUserTag h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
            </CardBody>
          </Card>
        </Link>
        <Link to={{
          pathname: `/admin/list-status/13/${productRequest}`,
          state: 12
        }}>
          <Card minH="83px">
            <CardBody>
              <Flex flexDirection="row" align="center" justify="center" w="100%">
                <Stat me="auto">
                  <StatLabel
                    fontSize="sm"
                    color="gray.400"
                    fontWeight="bold"
                    pb=".1rem"
                  >
                    Equipos de <br/>Baja
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                      {countDashboardBaja}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                  <FaArrowCircleDown h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
            </CardBody>
          </Card>
        </Link>
        <Link to={{
          pathname: `/admin/list-status/99/${productRequest}`,
          state: 12
        }}>
          <Card minH="83px">
            <CardBody>
              <Flex flexDirection="row" align="center" justify="center" w="100%">
                <Stat me="auto">
                  <StatLabel
                    fontSize="sm"
                    color="gray.400"
                    fontWeight="bold"
                    pb=".1rem"
                  >
                    Proximos para <br/>Devolución
                  </StatLabel>
                  <Flex>
                    <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                      {countDashboardProximosDevolucion}
                    </StatNumber>
                  </Flex>
                </Stat>
                <IconBox as="box" h={"45px"} w={"45px"} bg={iconTeal}>
                  <FaClock h={"24px"} w={"24px"} color={iconBoxInside} />
                </IconBox>
              </Flex>
            </CardBody>
          </Card>
        </Link>
      </SimpleGrid>
      <Grid
        templateColumns={{ md: "1fr", lg: "1.8fr 1.2fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my="26px"
        gap="24px"
      >
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
        templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
        gap="24px"
        mb={{ lg: "26px" }}
      >
        <Card p="16px">
          <CardHeader py="12px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Software
            </Text>
          </CardHeader>
          <CardBody>
            <Flex direction="column" w="100%">
              <Card
                py="1rem"
                height={{ sm: "200px" }}
                width="100%"
                bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                position="relative"
              >
                <Chart
                  options={barChartOptions}
                  series={barChartData}
                  type="bar"
                  width="100%"
                  height="100%"
                />
              </Card>
            </Flex>
          </CardBody>
        </Card>

        <Card p="16px">
          <CardHeader py="12px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Hardware
            </Text>
          </CardHeader>
          <CardBody>
            <Flex direction="column" w="100%">
              <Card
                py="1rem"
                height={{ sm: "200px" }}
                width="100%"
                bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                position="relative"
              >
                <Chart
                  options={barChartOptionsProd}
                  series={barChartDataProd}
                  type="bar"
                  width="100%"
                  height="100%"
                />
              </Card>
            </Flex>
          </CardBody>
        </Card>
      </Grid>
    </Flex>
  );
}
