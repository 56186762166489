  // Chakra imports
import {
    Button,
    Flex,
    Grid,
    Text,
    useColorModeValue,
    Table,
    Tbody,
    Th,
    Tr,
    Thead,
    IconButton,
    Spinner,
    Tooltip 
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import React, { Fragment, useState, useEffect } from "react";
import ListRequestPre from "components/Tables/ListRequestPre.js";
import ReactPaginate from 'react-paginate';
import { AiOutlinePlus } from "react-icons/ai";
import Modal from "react-modal";
import Swal from 'sweetalert2';
import Select, { components } from 'react-select';
import InputText from "components/Form/InputText";
import InputNumber from "components/Form/InputNumber";
import InputAmount from "components/Form/InputAmount";
import AuthApi from "../../api/auth";
import InputFilterTable from "components/Form/InputFilterTable";
import InputTexarea from "components/Form/InputTexarea"; 

function ListPre() {
    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
    
    const [titlePage, setTitlePage] = useState('Listado');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [listRequest, setListRequest] = useState([]);
    const [filters, setFilters] = useState({
        description: '',
        status: '',
        resource: '',
        company: '',
        date_register: '',
    });
    const [showFilters, setShowFilters] = useState({
        description: false,
        status: false,
        resource: false,
        company: false,
        date_register: false,
    });
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;
    const [errors, setErrors] = useState({ description_: false, company_:false, resource_:false});

    const [descriptionPre, setDescriptionPre] = useState();
    const [companyPre, setCompanyPre] = useState();
    const [resourcePre, setResourcePre] = useState();
    const [isShowErrorForm, setIsShowErrorForm] = useState(false);
    const [isLoadingSaveWait, setIsLoadingSaveWait] = useState(false);
    
  const customStylesModal = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)", 
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      borderRadius: '10px',
      border: 'none',
    }
  };
    
    const getListPre = async () => {
        try {
          let response = await AuthApi.getListPre();
          if (response.data.success === true) {
       
            let arrayData = [];
              response.data.data.forEach(element => {
                arrayData.push(element);
                console.log(element)
              });
              setListRequest(arrayData);
          };
          
        } catch (err) {
          console.log(err);
        };
    };

    const handleViewRequest = (id) => {
        console.log(id)
    };

    const handleCaptureValueDescription = (value) => {
        setDescriptionPre(value);
      };

    const handleCaptureValueResource = (value) => {
        setResourcePre(value);
      };

    const  handleCaptureValueCompany = (value) => {
        setCompanyPre(value);
      };

    const handleFilterChange = (e) => {
        //const { name, value } = e.target;
        setFilters({ ...filters, [e.name]: e.value });
        setCurrentPage(0); 
    };

    const saveRequestPre = async () => {

        setIsLoadingSaveWait(true);
        let newErrors = {};
        
        newErrors = {
            description_: !descriptionPre,
            company_: !companyPre,
            resource_: !resourcePre
        };
        
        setErrors(newErrors);
    
        const formIsValid = Object.values(newErrors).every(error => !error);
        
        if (formIsValid) {
          setIsShowErrorForm(false);
          
          let dataRequest = {
            'company' : companyPre,
            'resource' : resourcePre,
            'description' : descriptionPre
          };
          console.log(dataRequest);
          
          
          try {
            let response = await AuthApi.saveRequestInitial(dataRequest);
            console.log(response.data);
            if (response.data.success === true) {
              
                  Swal.fire({
                    icon: 'success',
                    title: 'Registro de Pre Solicitud',
                    text: 'Registro satisfactorio',
                    //position: 'top-end',
                    showConfirmButton: false,   
                    timer: 3000,
                    timerProgressBar: true 
                  });
                  setIsLoadingSaveWait(false)
                  setIsModalOpen(false)
                  getListPre()
                  //clearScreen()
    
            }
          } catch (err) {
            console.log(err);
          }
    
        } else {
          setIsLoadingSaveWait(false)
          console.log("Hay errores en el formulario");
          setIsShowErrorForm(true);
        }
        
        
      };

    const openModalNew = async () => {
        setIsModalOpen(true)
    };

    const toggleFilter = (columnName) => {
        setShowFilters((prevShowFilters) => ({
            ...prevShowFilters,
            [columnName]: !prevShowFilters[columnName],
        }));
    };

    const filteredData = listRequest.filter(row => {
        return (
            row.description.toLowerCase().includes(filters.description.toLowerCase())
            && row.status.toLowerCase().includes(filters.status.toLowerCase())
            && row.resource.toLowerCase().includes(filters.resource.toLowerCase())
            && row.company.toLowerCase().includes(filters.company.toLowerCase())
            && row.date_register.toLowerCase().includes(filters.date_register.toLowerCase())
        );
        //return ({   })
    });

  useEffect(() => {
    getListPre();
  }, [])

  const offset = currentPage * itemsPerPage;
  const currentData = filteredData.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(listRequest.length / itemsPerPage);

  const handlePageClick = (data) => {
      setCurrentPage(data.selected);
  };

    return (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
            <Grid templateColumns={{ sm: "1fr" }}>

                <Card my={{ lg: "24px" }} me={{ lg: "24px" }} w={{ base: "100%", lg: "120%" }}>
                    <Flex direction="column">
                        <CardHeader py="12px">
                            <Text color={textColor} fontSize="lg" fontWeight="bold">
                                {titlePage}
                            </Text>
                            <Flex  direction="row" w="80%" wrap="wrap" justifyContent="end">
                                <Button bg="green.500" color="white" fontSize="xs" p="8px 32px" m={2} onClick={() => openModalNew()}>
                                    Agregar Nueva
                                </Button>
                            </Flex>
                        </CardHeader>
                        <CardBody>
                            <Flex direction="column" w="100%" mb="8" >
                                <Flex direction="row">
                                    <Table variant="simple" color={textColor}>
                                        <Thead>
                                            <Tr my=".8rem" pl="0px" color="gray.500">
                                                <Th></Th>
                                                <Th pl="0px" color="gray.500">
                                                    DESCRIPCIÓN
                                                    {<InputFilterTable label={"Buscar por Descripción"} nameInput={"description"} filter={filters.description} showFilters={showFilters.description}  captureValue={handleFilterChange} showFilter={toggleFilter}/>} 
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    RECURSO
                                                    {<InputFilterTable label={"Buscar por Recurso"} nameInput={"resource"} filter={filters.resource} showFilters={showFilters.resource}  captureValue={handleFilterChange} showFilter={toggleFilter}/>} 
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    EMPRESA
                                                    {<InputFilterTable label={"Buscar por Empresa"} nameInput={"company"} filter={filters.company} showFilters={showFilters.company}  captureValue={handleFilterChange} showFilter={toggleFilter}/>} 
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    ESTADO
                                                    {<InputFilterTable label={"Buscar por Estado"} nameInput={"status"} filter={filters.status} showFilters={showFilters.status}  captureValue={handleFilterChange} showFilter={toggleFilter}/>} 
                                                </Th>
                                                <Th pl="0px" color="gray.500">
                                                    FECHA DE SOLICITUD
                                                    {<InputFilterTable label={"Buscar por Fecha"} nameInput={"date_register"} filter={filters.date_register} showFilters={showFilters.date_register}  captureValue={handleFilterChange} showFilter={toggleFilter}/>} 
                                                </Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {currentData.map((row, index) => {
                                                return (
                                                    <ListRequestPre
                                                        key={index}
                                                        id={row.id}
                                                        description={row.description}
                                                        status={row.status}
                                                        resource={row.resource}
                                                        company={row.company}
                                                        date_register={row.date_register}
                                                    />
                                                );
                                            })}
                                        </Tbody>
                                    </Table>
                                </Flex>
                                <ReactPaginate
                                    previousLabel={'Anterior'}
                                    nextLabel={'Siguiente'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />
                            </Flex>
                        </CardBody>
                    </Flex>
                </Card>
            </Grid>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Formulario de Nueva Solicitud"
                ariaHideApp={false}
                style={customStylesModal}
            >
                <Flex direction="column" p="20px">
                    <CardHeader py="12px">
                        <Text color={textColor} fontSize="lg" fontWeight="bold"></Text>
                    </CardHeader>
                    <CardBody>
                        <Flex direction="column" w="100%">
                            <Flex direction="column" w="100%">
                                <InputTexarea label={"Descripción"} placeholder={'Ingrese descripción'} defaultValue={descriptionPre}  error={errors.description_} captureValue={handleCaptureValueDescription} />
                            </Flex>
                            <Flex direction="column" w="100%">
                                <InputText label={"Recurso"} placeholder={'Ingrese recurso'} defaultValue={resourcePre}  error={errors.resource_} captureValue={handleCaptureValueResource} />
                            </Flex>
                            <Flex direction="column" w="100%">
                                <InputText label={"Empresa"} placeholder={'Ingrese empresa'} defaultValue={companyPre}  error={errors.company_} captureValue={handleCaptureValueCompany} />
                            </Flex>
                            <Flex direction="column" w="100%" mr="8">
                                <Button  bg="green.500" color="white" mt={4} onClick={() => saveRequestPre()}>{isLoadingSaveWait ? <Spinner size="xs" /> : 'Guardar'}</Button>
                            </Flex>
                        </Flex>
                    </CardBody>
                </Flex>
            </Modal>
        </Flex>
    );
}

export default ListPre;